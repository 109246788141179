<template>
  <v-container>
    <v-row>
      <v-col>
        <v-toolbar
          dense
          color="red"
          dark
          class="text-h6"
        >
          Encuesta
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          elevation="2"
          color="blue-grey lighten-5"
        >
          <v-row class="mx-1">
            <v-col>
              <h3><v-icon>mdi-store</v-icon>OPORTUNIDAD DE:</h3>
              <v-text-field
                v-model="nomcont"
                label="Contacto"
                readonly
                class="mb-1"
                hide-details
              />
              <v-text-field
                v-model="empresa"
                label="Empresa"
                readonly
                hide-details
              />
            </v-col>
          </v-row>
        </v-card>
      
        <h4 class="text-center mt-5">
          ¿Le gustó la demostración que le dieron?
        </h4>
        <v-row justify="center">
          <v-radio-group
            row
          >
            <v-radio
              v-model="p1si"
              label="Si"
              color="green"
              value="si"
              class="mr-4"
            />
            <v-radio
              v-model="p2no"
              class="ml-4"
              label="No"
              color="red darken-3"
              value="no"
            />
          </v-radio-group>
        </v-row>
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="12">
        <h4 class="text-center">
          ¿Le explicaron todos los puntos de la cotización?
        </h4>
        <v-radio-group
          column
          class="mt-1"
        >
          <v-radio
            label="Si, se aclararon todos los puntos"
            color="green"
            value="p2"
            class="mr-4"
          />
          <v-radio
          
            label="No, faltaron cosas por explicar"
            color="red darken-3"
            value="no"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="12">
        <h4 class="text-center">
          Del 1 al 10, ¿Como calificaría la demostración que le dieron?
        </h4>
        <v-slider
          v-model="bpm"
          thumb-label="always"
          max="10"
          :thumb-size="24"
          always-dirty
          min="1"
          :color="color"
          track-color="grey"
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="12">
        <h4 class="text-center">
          ¿El asesor fue puntual a la cita?
        </h4>
        <v-row justify="center">
          <v-radio-group
            row
          >
            <v-radio
              label="Si"
              color="green"
              value="si"
              class="mr-4"
            />
            <v-radio
              class="ml-4"
              label="No"
              color="red darken-3"
              value="no"
            />
          </v-radio-group>
        </v-row>
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="12">
        <h4 class="text-center">
          ¿El Asesor resolvió todas sus dudas?
        </h4>
        <v-row justify="center">
          <v-radio-group
            row
          >
            <v-radio
              label="Si"
              color="green"
              value="si"
              class="mr-4"
            />
            <v-radio
              class="ml-4"
              label="No"
              color="red darken-3"
              value="no"
            />
          </v-radio-group>
        </v-row>
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="12">
        <h4 class="text-center">
          ¿ Cree que el sistema cumple sus necesidades?
        </h4>
        <v-textarea
          label="Escribe aqui"
          outlined
          rows="3"
          row-height="15"
          dense
          auto-grow
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="12">
        <h4 class="text-center">
          ¿Cuando le gustaría que le llamen para saber si ya tomo una decisión?
        </h4>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Fecha"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="center"
      class="mt-1"
    >
      <v-col cols="12">
        <h4 class="text-center">
          ¿Que fue lo que mas le llamo la atencion de nuestro sistema?
        </h4>
        <v-textarea
          label="Escribe aqui"
          outlined
          rows="3"
          row-height="15"
          dense
          auto-grow
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters,} from 'vuex'
export default {
  data () {
    return {
      ex7: 'red',
      ex8: 'primary',
      checkbox: true,
        
      //barra de colores de calificacion
      bpm: 1,

      //
      p1si:'',
      p2no:'',

      //Fecha
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      menu2: false,

      empresa:'',
      nomcont:'',

    }
  },
    
  computed: {
    ...mapGetters('login',['getdatosUsuario']),
    ...mapGetters('oportunidad',['getOportunidad']),
    color () {
      if (this.bpm <= 6) return 'red'
      if (this.bpm <= 8) return 'orange'
      if (this.bpm <= 10) return 'green'
      return 'red'
    },
    animationDuration () {
      return `${60 / this.bpm}s`
    },
  },

  watch:{
    getOportunidad(){
      // this.limpiar()
      this.actualizarOportunidad()
    },
  },

  methods:{

    actualizarOportunidad(){
      //Mandar llamar a getoportunidad para cargar los datos de la oportunidad seleccionada
      this.getOportunidad
      console.log(this.getOportunidad)

      this.obs = this.getOportunidad.obs
      this.asunto = this.getOportunidad.asunto
      this.nomcont = this.getOportunidad.nomcont
      this.empresa = this.getOportunidad.empresa
    },


    validate () {
      return this.$refs.form.validate()
    },


    CargarInfo(cita){
      this.empresa  = cita.empresa
      this.obs      = 	cita.obs
      this.nomcont  =	cita.nomcont
    },
  }
}
</script>

<style>
  @keyframes metronome-example {
    from {
      transform: scale(.5);
    }

    to {
      transform: scale(1);
    }
  }

  .v-avatar--metronome {
    animation-name: metronome-example;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
</style>